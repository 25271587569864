










































































































































































































import Vue from "vue";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  COMMON_LOADING,
  FETCH_SKILLS_GROUP,
  GET_JOB_BY_ID
} from "@/store/modules/common/constants";
import {
  GET_MATCHING_JOB_BY_ID,
  JOB_ID_FOR_UPSKILL,
  GET_JOB_MATCH_AGAINST_JOB_ID
} from "@/store/modules/candidates/constants";
import { ViewMatchingJob } from "@/interfaces/candidate/view_matching_job";
import router from "@/router";
import { ROOT_ERROR } from "@/store/modules/root/constants";
import { USER_PROFILE } from "@/store/modules/auth/constants";
import { clean_string, format_overall_score } from "@/utils/global";
import ExtraDetails from "@/components/shared/view_job_details/ExtraDetails.vue";
import JobDescriptionCard from "@/components/shared/view_job_details/JobDescriptionCard.vue";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
import SkillsChips from "@/components/shared/SkillsChips.vue";
import RootLoader from "@/components/shared/RootLoader.vue";
import { get_color_by_matching_score } from "@/utils/colors";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "ViewMatchingJob",
  data(): ViewMatchingJob {
    return {
      loading: true,
      data: null,
      active_skill_group: "",
      edit_icon: require("@/assets/icons/linear/edit.svg"),
      industry_icon: require("@/assets/icons/bold/devices.svg"),
      track_icon: require("@/assets/icons/bold/mobile.svg"),
      experience_icon: require("@/assets/icons/bold/brifecase-tick.svg"),
      upskill_view: false,
      font_color: "",
      background_color: "",
      color_code: ""
    };
  },
  components: {
    RootLoader,
    SkillsChips,
    SkillsGroup,
    JobDescriptionCard,
    ExtraDetails,
    GoBackHeader
  },
  // Validating route param type is int on router enter
  beforeRouteEnter(to, from, next) {
    const param_id = parseInt(to.params.id);
    if (isNaN(param_id)) next(false);
    else next();
  },

  // Validating route param type is int on router param update
  beforeRouteUpdate(to, from, next) {
    const param_id = parseInt(to.params.id);
    if (isNaN(param_id)) next(false);
    else {
      // this.set_job_id_for_upskill(param_id);
      this.init_data(param_id);
      next();
    }
  },
  async created() {
    await this.init_data(parseInt(this.$route.params.id));
  },
  computed: {
    ...mapGetters("common", {
      common_loading: COMMON_LOADING,
      get_site_direction: SITE_DIRECTION
    }),
    ...mapGetters("candidate", {
      get_matching_job: GET_MATCHING_JOB_BY_ID
    }),
    ...mapGetters("auth", {
      user_profile: USER_PROFILE
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  methods: {
    clean_string,
    ...mapMutations("candidate", {
      set_job_id_for_upskill: JOB_ID_FOR_UPSKILL
    }),
    ...mapActions("common", {
      get_job_by_id: GET_JOB_BY_ID,
      fetch_skills: FETCH_SKILLS_GROUP
    }),
    ...mapActions("candidate", {
      get_matching_job_by_id: GET_JOB_MATCH_AGAINST_JOB_ID
    }),
    format_overall_score,
    ...mapMutations({
      root_error: ROOT_ERROR
    }),
    async init_data(job_id: number) {
      this.loading = true;
      // Get matching job from store
      const jid = sessionStorage.getItem("job_id_next_move_assessment");
      const details = sessionStorage.getItem("next_move_job_details");
      if (!details) {
        if (jid) {
          try {
            // sessionStorage.removeItem("job_id_next_move_assessment"); // Remove job id from session storage
            const job = await this.get_job_by_id(parseInt(jid)); // Get job
            // Parse skills list
            if (job.skills_list) {
              let skills: string | string[] = [];
              skills = JSON.parse(job.skills_list);
              if (typeof skills === "string") skills = eval(skills);
              job.skills_list = await this.fetch_skills(skills);
            }
            // Fetch skills gap
            const skill_gap = await this.get_matching_job_by_id({
              job_id: parseInt(jid),
              cv_id: this.user_profile.info.file_id
            }); // Get matching skills
            job.score = skill_gap.score; // Set score

            const required_skills = skill_gap.matched.concat(skill_gap.gap); // Concat Skill Gap & Skill Matched

            // Creating Data Payload For View Job
            this.data = {
              matching_job: job,
              user_profile: this.user_profile,
              matched_skills: skill_gap.matched,
              skills_gap: skill_gap.gap,
              required_skills
            };
            this.set_job_id_for_upskill(-1);
            sessionStorage.setItem(
              "next_move_job_details",
              JSON.stringify(this.data)
            );
          } catch (e) {
            this.root_error(this.$t("errors.job-not-exist"));
            await router.push({
              name: "candidate-dashboard"
            });
          }
        } else {
          this.set_job_id_for_upskill(job_id);
          const matching_job = await this.get_matching_job(job_id);
          const searched_matching_job = await this.get_matching_job(
            job_id,
            true
          );
          // If matching job not exist => navigate back to dashboard
          if (!matching_job && !searched_matching_job) {
            this.root_error(this.$t("errors.job-not-exist"));
            await router.push({
              name: "candidate-dashboard"
            });
          } else {
            // Select Job payload
            const job_to_view = searched_matching_job
              ? searched_matching_job
              : matching_job;
            // Concat Skill Gap & Skill Matched
            const required_skills = job_to_view.matching_data.matched.concat(
              job_to_view.matching_data.gap
            );
            // Creating Data Payload For View Job
            this.data = {
              matching_job: job_to_view,
              user_profile: this.user_profile,
              matched_skills: job_to_view.matching_data.matched,
              skills_gap: job_to_view.matching_data.gap,
              required_skills
            };
          }
        }
      } else {
        this.set_job_id_for_upskill(-1);
        this.data = JSON.parse(details);
      }
      if (this.data) {
        const color = get_color_by_matching_score(this.data.matching_job.score); // Get random color
        this.font_color = `color: ${color}`;
        this.background_color = `background: ${color}`;
        this.color_code = color;
      }
      this.loading = false;
    },
    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    },
    /**
     * Function to navigate to upskill career page
     */
    async upskill_career() {
      this.upskill_view = true;
      if (this.data) {
        await router.push("/candidate/jobs/upskill-career");
      }
    }
  },
  destroyed() {
    if (!this.upskill_view) this.set_job_id_for_upskill(null);
  }
});
